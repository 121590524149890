<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input v-model="searchParams.userName" size='small' style="width:150px;margin:5px;" placeholder='用户名'></a-input>
        <a-button type="primary"  @click="search" size='small' style="margin-left:10px;">搜索</a-button>
        <a-button  @click="handleShowLianLianDrawPrice('bind')" size='small' style="margin-left:20px;">连连保证金户头提现</a-button>
        <a-button  @click="handleShowLianLianChangePrice('bind')" size='small' style="margin-left:20px;">连连平台户头转账到保证金户头</a-button>
        <a-button  @click="handleShowLianLianDrawPrice('postage')" size='small' style="margin-left:20px;">快递费户头提现</a-button>
        <a-button  @click="handleShowLianLianChangePrice('postage')" size='small' style="margin-left:20px;">连连平台户头转账到快递费户头</a-button>
        <a-button  @click="handleShowLianLianDrawPrice('service')" size='small' style="margin-left:20px;">连连服务费户头提现</a-button>
        <a-button  @click="handleShowLianLianDrawPrice('merchant')" size='small' style="margin-left:20px;">商家营收户头体现</a-button>
      </div>
      <a-button size='small' style="margin:5px;" @click="openCreate({})">创建后台账号</a-button>
    </div>
    <div class="table-content" style="padding-top:20px;">
      <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>record.id||n">
        <div slot="setup" slot-scope="row">
          <a @click="openModal(row)" style='margin-right:5px'>修改密码</a>
          <a @click="openCreate(row)" style='margin-right:5px'>修改用户信息</a>
          <a-popconfirm
              title="确定要操作吗?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="unLockOrLock(row.id)"
          >
            <a style='margin-left:5px' v-if="row.enableState === 0">冻结</a>
            <a style='margin-left:5px' v-else>解冻</a>
          </a-popconfirm>
          <a-popconfirm
              title="确定要操作吗?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="delAccount(row.id)"
          >
            <a style='margin-left:5px' >删除</a>
          </a-popconfirm>

        </div>
      </a-table>
    </div>
    <a-modal v-model="visible" title="系统账号" ok-text="保存" cancel-text="取消" @cancel='resetForms' @ok="hideModal">
      <a-form-model
          ref="ruleForm"
          :model="forms"
          :rules="rules"
      >
        <a-form-model-item label="新密码" prop="newPwd" >
          <a-input placeholder='请输入密码' v-model="forms.newPwd" type="password"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="createVisible" title="系统账号" ok-text="提交" cancel-text="取消" @cancel='resetForms' @ok="hideCreateModal">
      <a-form-model
          ref="ruleCreateForm"
          :model="createForms"
          :rules="createRules"
      >
        <a-form-model-item label="用户名" prop="userName" >
          <a-input placeholder='请输入用户名' v-model="createForms.userName" ></a-input>
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="passWord" v-if="createForms.id==null">
          <a-input placeholder='请输入密码' v-model="createForms.passWord" type="password"></a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="platformPhone" >
          <a-input placeholder='请输入手机号' v-model="createForms.platformPhone"></a-input>
        </a-form-model-item>
        <a-form-model-item label="角色" prop="roleId" allowClear placeholder="角色">
          <a-select style="width: 120px;" @change="handleChange" v-model="createForms.roleId" prop="roleId"  size='small'>
            <a-select-option  v-for="msg in roleList" :key="msg.id" :value="msg.id">
              {{msg.roleName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
<!--   户头提现    -->
    <a-modal
        v-model="lianLianDrawShowModal"
        :title="drawPriceTypeMapTitleText[drawPriceType]"
        @cancel='handleLianLianDrawCancel'
    >
      <template #footer>
        <div></div>
      </template>
      <a-form-model ref="ruleCreateForms">
        <a-form-model-item label="提现金额" prop="money" >
          <a-input placeholder='请输入提现金额' type="number" v-model="lianLianDrawForms.money" ></a-input>
        </a-form-model-item>

        <a-form-model-item label="获取验证码" prop="code" >
          <div class="flex-start-center">
            <a-input placeholder='请输入手机验证码' type="tel" v-model="userDrawParams.verifyCode" ></a-input>
            <a-button
                :disabled="isSendNoteCodeStatus"
                type="primary"
                @click="handleLianLianDrawConfirm"
                style="margin-left:10px;"
            >{{ !isSendNoteCodeStatus ? '获取验证码' : `重新获取验证码${showNoteCodeCountTime}s` }}</a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item label="确定提现" prop="code" >
          <div class="flex-start-center">
            <a-button
                type="primary"
                @click="handleConfirmDrawPrice"
                style="margin-left:10px;"
            >确定提现</a-button>
          </div>
        </a-form-model-item>


      </a-form-model>
    </a-modal>
<!--  转账  -->
    <a-modal
        v-model="lianLianChangeShowModal"
        :title="changePriceTypeMapTitleText[changePriceType]"
        @cancel='handleLianLianChangeCancel'
        @ok="handleLianLianChangeConfirm"
    >
      <a-form-model>
        <a-form-model-item label="转账金额" prop="money" >
          <a-input placeholder='请输入转账金额' type="number" v-model="lianLianChangeForms.money" ></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  data(){
    return {
      lianLianChangeShowModal: false,
      lianLianChangeForms: {
        money: undefined,
        accountType: 'deposit'
      },
      countDownEl: null,
      userDrawParams: {
        verifyCode: undefined,
        token: undefined,
        txnSeqno: undefined,
        totalAmount: undefined,
        accountType: 'deposit'
      },
      verifyCode: undefined,
      lianLianDrawShowModal: false,
      // 显示验证码
      isSendNoteCodeStatus: false,
      showNoteCodeCountTime: 60,
      columns:[
        {title:'ID',dataIndex:'id'},
        {title:'用户名',dataIndex:'userName'},
        {title:'角色',dataIndex:'roleName'},
        {title:'用户状态',dataIndex:'enableStateName'},
        {title:'手机号',dataIndex:'platformPhone'},
        {title:'操作',align:'center',scopedSlots: { customRender: 'setup' },},
      ],
      data:[],
      searchParams:{
        pageSize:10,
        pageNum:1,
        userName:''
      },
      rules:{
        newPwd:[{ required: true, message: '不能为空', trigger: 'blur' }],
        oldPwd:[{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      createRules:{
        passWord:[{ required: true, message: '不能为空', trigger: 'blur' }],
        userName:[{ required: true, message: '不能为空', trigger: 'blur' }],
        roleId:[{ required: true, message: '不能为空', trigger: 'blur' }],
        platformPhone:[{ required: true, message: '不能为空', trigger: 'blur' }],
      },
      times:[],
      forms:{
      },
      createForms:{},
      lianLianDrawForms: {
        money: undefined,
      },
      visible:false,
      createVisible:false,
      pagination:{
        total:0,
        current:1,
        showTotal:(total)=>`共${total}条`,
      },
      loading:false,
      roleList:[],
      drawPriceType: 'bind',
      changePriceType: 'bind',
      changePriceTypeMapTitleText: {
        bind: '连连户头转账到保证金户头',
        postage: '连连户头转账到快递费户头',
      },
      drawPriceTypeMapType: {
        bind: 'deposit',
        postage: 'logistics',
        service: 'service',
        merchant: 'merchant',
      },
      drawPriceTypeMapTitleText: {
        bind: '保证金户头提现',
        postage: '快递费户头体现',
        service: '服务费户头体现',
        merchant: '商家营收户头提现',
      }
    }
  },
  computed:{
  },
  watch:{
  },
  created(){
    this.getData(this.searchParams)
    this.getRole()
  },
  methods:{
    /** 确定转账 */
    async handleLianLianChangeConfirm() {
      if (!this.lianLianChangeForms.money) return this.$message.warning('金额不能为空')
      this.lianLianChangeForms.accountType = this.drawPriceTypeMapType[this.changePriceType]
      const res = await this.axios('/dq_admin/userWithdrawDetail/lianlianPlatformAccountToDepositAccount', {
        params: this.lianLianChangeForms
      })
      if (res.status !== '200') return
      this.$message.info('转账成功')
      this.handleLianLianChangeCancel()
    },
    /** 连连平台户头转账到保证金户头 */
    handleShowLianLianChangePrice(type) {
      this.changePriceType = type
      this.lianLianChangeShowModal = true
    },
    /** 取消转账 */
    handleLianLianChangeCancel() {
      this.lianLianChangeForms = {
        money: undefined,
        accountType: 'deposit'
      }
      this.lianLianChangeShowModal = false
    },
    /** 确定提现 */
    async handleConfirmDrawPrice() {
      if (!this.userDrawParams.verifyCode) return this.$message.warning('请输入验证码')
      this.userDrawParams.accountType = this.drawPriceTypeMapType[this.drawPriceType]
      const res = await this.axios.post('/dq_admin/userWithdrawDetail/lianlianDepositAccountWithdrawValidationSms',this.userDrawParams)
      if (res.status !== '200') return
      this.$message.info('提现成功')
      this.handleLianLianDrawCancel()
    },
    /** queding  */
    async handleLianLianDrawConfirm() {
      if (!this.lianLianDrawForms.money) return this.$message.warn('请输入提现金额')
      this.lianLianDrawForms.accountType = this.drawPriceTypeMapType[this.drawPriceType]
      const res = await this.axios('/dq_admin/userWithdrawDetail/lianlianDepositAccountWithdraw',{
        params: this.lianLianDrawForms
      })
      if (res.status !== '200') return
      if (res.data.payType === 'smsValidate') {
        Object.assign(this.userDrawParams, res.data)
        this.isSendNoteCodeStatus = true
        this.handleDownCount()
      }
    },
    /** 设置倒计时 */
    handleDownCount() {
      this.countDownEl = setInterval(() => {
        if (this.showNoteCodeCountTime) {
          this.showNoteCodeCountTime--
        } else {
          this.isSendNoteCodeStatus = false
          this.showNoteCodeCountTime = 60
          clearInterval(this.countDownEl)
        }
      }, 1000)
    },
    /** 取消 */
    handleLianLianDrawCancel() {
      this.lianLianDrawShowModal = false
      this.lianLianDrawForms = {
        money: undefined,
        accountType: 'deposit',
      }
      this.userDrawParams = {
        verifyCode: undefined,
        token: undefined,
        txnSeqno: undefined,
        totalAmount: undefined,
        accountType: 'deposit',
      }
      this.lianLianDrawShowModal = false
    },
    /** 连连户头提现 */
    handleShowLianLianDrawPrice(type) {
      this.drawPriceType = type
      this.lianLianDrawShowModal = true
    },
    async getData(params){
      this.loading = true
      const res = await this.axios('/platform/platformaccount/list',{params:params})
      this.loading = false
      const {records,total} = res.data
      this.data = records
      this.pagination.total = total
    },
    async getRole(){
      const res = await this.axios('/platform/platformrole/list')
      this.roleList = res.data
    },
    async unLockOrLock(n){
      const res = await this.axios('/platform/platformaccount/unLockOrLock',{params:{id:n}})
      if(res.status === 'SUCCESS'){
        this.$message.success(res.message)
        this.getData(this.params)
      }
      this.getData(this.searchParams)
    },
    async delAccount(n){
      const res = await this.axios('/platform/platformaccount/delAccount',{params:{id:n}})
      if(res.status === 'SUCCESS'){
        this.$message.success(res.message)
        this.getData(this.params)
      }
      this.getData(this.searchParams)
    },
    openModal(row){
      this.visible = true
      const id = row&&row.id ? row.id : 0
      this.IO = id ? 1 : 0
      this.forms = id ? JSON.parse(JSON.stringify(row)) : {}
    },
    search(){
      this.searchParams.pageNum = 1
      this.pagination.current = 1
      this.getData(this.searchParams)
    },
    changePage(page){
      const {current,pageSize} = page
      this.pagination.current = current
      this.searchParams.pageNum = current
      this.searchParams.pageSize = pageSize
      this.getData(this.searchParams)
    },
    changeTime(result){
      this.times = result
    },
    resetForms(){
      this.forms = {}
      this.createForms = {}
      this.$refs.ruleForm.resetFields();
    },
    hideModal(){
      this.$refs.ruleForm.validate(valid => {
        if(valid){
          this.submit(this.forms)
        }else{
          return false;
        }
      });
    },
    hideCreateModal(){
      this.$refs.ruleCreateForm.validate(valid => {
        if(valid){
          this.createSubmit(this.createForms)
        }else{
          return false;
        }
      });
    },
    createSubmit(forms){
      const post_url = this.IO ? '/platform/platformaccount/edit' :'/platform/platformaccount/add'
      this.axios.post(post_url,{...forms}).then(res=>{
        if(res.status == '200'){
          this.$message.success(res.message)
          this.createVisible = false
          this.getData(this.params)
        }
      })
    },
    submit(forms){
      const post_url = '/platform/platformaccount/editPwd'
      this.axios.post(post_url,{...forms}).then(res=>{
        if(res.status == '200'){
          this.$message.success(res.message)
          this.visible = false
          this.getData(this.params)
        }
      })
    },
    openCreate(row){
      const id = row&&row.id ? row.id : 0
      this.IO = id ? 1 : 0
      this.createForms = id ? JSON.parse(JSON.stringify(row)) : {}
      this.createVisible = true
    },
    handleChange(){}
  }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
</style>